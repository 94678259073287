// extracted by mini-css-extract-plugin
export var benefitsSectionMvp = "D_fb";
export var businessesSectionMvp = "D_d8";
export var clientsMvpSection = "D_fm";
export var devApproachSectionMvp = "D_fc";
export var domainExpertiseMvpSection = "D_d7";
export var launchProductSectionMvp = "D_fd";
export var launchProductSectionMvp__testimonial = "D_ff";
export var ourAchievementsMvp = "D_fl";
export var servicesCardBorderIconMvpSection = "D_d6";
export var servicesDevExpertiseMvp = "D_fg";
export var servicesProcessMvpSection = "D_d9";
export var successStoriesMvpSection = "D_fj";
export var technologiesSectionMvp = "D_fh";
export var whyChooseMvpSection = "D_fk";